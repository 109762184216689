import React from "react"

import Layout from "../components/flayout"
import ContentPage from "../components/contentPage"

const Page = () => (
  <Layout darkHeader={true} seoFields={seoFields}>
    <ContentPage
      title={'Disclaimer'}
    >
        <p>MProfit makes every effort to avoid errors, omissions, mistakes, discrepancies, bugs and inaccuracies in the reports and corporate action details that it provides to MProfit users. However, please note that the accuracy of reports and corporate action details in MProfit is subject to the accuracy of data either imported or manually entered in MProfit, as well as the accuracy of current/historical pricing & corporate action data provided by third-party data providers.</p>

        <p>Computation of capital gains in MProfit is as per Income Tax rules. Accuracy of capital gain reports in MProfit is subject to the accuracy of historical prices (FMVs) as of January 31, 2018, that are provided by third-party data providers.</p>

        <p>The Turnover Report (Section 44AB) under the MProfit F&O Module in the MProfit for Desktop application is provided for informational purposes only. It has certain limitations in terms of computing turnover for options that are settled with current prices. The accuracy of this report is subject to the accuracy of imported transactions as well as following proper Mark-to-Market (M2M) processes to generate profit/loss on a daily basis.</p>

        <p>If any mistake, error, omission, discrepancy, bug or inaccuracy in current prices, historical prices, FMV prices or corporate action details is noticed anywhere in MProfit software solutions, it may be brought to our notice for immediate correction. MProfit will make its best efforts to try and resolve the inaccuracy, subject to getting accurate data from third-party vendors or subject to technical limitations faced in the resolution of such inaccuracy.</p>

        <p>All MProfit users are advised to verify the accuracy of their MProfit reports and corporate action entries. All MProfit users are advised to verify the accuracy of their capital gain reports generated in MProfit before filing Income Tax Returns.</p>

        <p>MProfit will not be responsible for any loss, damage or inconvenience to anyone, of any kind, in any manner, resulting due to mistakes, errors, discrepancies, bugs or inaccuracies in MProfit reports, corporate action details, computations & import processes.</p> 
    </ContentPage>
  </Layout>
)

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "Disclaimer" 
  }

export default Page